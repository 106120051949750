import axios from 'axios';
import { getToken, logout } from "./Auth";
import Axios from 'axios';

export const URL_BASE = 'https://server-edi-telegram.polibrasnet.com.br/v1/api';
// export const URL_BASE = '//0.0.0.0:8000/v1/api';

export default async function api(others_headers, extra_options = {}) {
  const token = getToken();
  const headers = {
    'Content-Type': 'application/json',
    ...others_headers
  };

  if (!!token) {
    headers['Authorization'] = `FSToken ${token}`
  }

  const baseUrl = URL_BASE;
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 60000,
    headers,
    ...extra_options,
  });

  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if(Axios.isCancel(error)) {
      return Promise.reject(error); 
    }

    if (error.response.status === 401) {
      logout();
      window.location = '/';
    }
    return Promise.reject(error);
  });

  return instance;
}